import React, { useEffect, useState } from "react";

function Question(props) {
  const {
    question,
    round,
    options,
    onAnswer,
    correctAnswer,
    doubleDipActive,
    doubleDipFirstAttempt,
    answerRevealed,
    renderTimer
  } = props;
  const [locked, setLocked] = useState(-1);
  const [showOptions, setShowOptions] = useState(false);

  const getAnswerClass = (index) => {
    if (locked === index) {
      if (!answerRevealed) {
        return "question__option--locked";
      } else if (correctAnswer === index) {
        return "question__option--correct";
      } else {
        return "question__option--wrong";
      }
    }
    return "question__option--unlocked";
  };

  useEffect(() => {
    setLocked(-1);
  }, [question]);

  useEffect(() => {
    setShowOptions(false)
    setTimeout(() => {
      setShowOptions(true);
    }, 4000);
  }, [question]);

  const onLocked = (index) => {
    setLocked(index);
    onAnswer(index);
  };

  const isDisabled = () => {
    if (doubleDipActive) {
      return locked !== -1 && doubleDipFirstAttempt;
    }
    return locked !== -1;
  };

  return (
    <div className="question">
      <div className="question__round">Round: {round}</div>
      <div className="question__timer">
        {renderTimer()}
      </div>
      <div className="question__title_wrapper">
        <div
          className="question__title"
          dangerouslySetInnerHTML={{ __html: question }}
        ></div>
      </div>
      <div
        className={`question__options ${
          isDisabled() ? "question__options--disabled" : ""
        }`}
      >
        {showOptions && options.map((option, index) => {
          return (
            <div
              className={`${getAnswerClass(index)} question__option`}
              key={index}
              onClick={() => !isDisabled() && onLocked(index)}
              dangerouslySetInnerHTML={{ __html: option }}
            ></div>
          );
        })}
      </div>
    </div>
  );
}

export default Question;