import "./App.css";
import { useCallback, useEffect, useRef, useState } from "react";
import Question from "./components/Question";
import QuestionList from "./components/QuestionList";
import Lifeline from "./components/Lifeline";
import round1 from "./questionData/round1.json";
import round2 from "./questionData/round2.json";
import round3 from "./questionData/round3.json";
import round4 from "./questionData/round4.json";
import round5 from "./questionData/round5.json";

const prizeMap = [
  1000, 2000, 3000, 5000, 10000, 15000, 20000, 25000, 30000, 40000, 50000,60000,75000,100000
];

const roundData = [round1, round2, round3, round4, round5];

const lockAudioString = "/assets/audios/lock.mp3";
const lifelineAudioString = "/assets/audios/lifeline.mp3";
const newAudioString = "/assets/audios/new.mp3";
const wrongAudioString = "/assets/audios/wrong.mp3";
const introAudioString = "/assets/audios/intro.mp3";
const lifelineIndexMap = {
  fiftyFifty: 0,
  doubleDip: 1,
  phoneAFriend: 2,
};

function App() {
  const [playMode, setPlayMode] = useState(false);
  const [liflines, setLifelines] = useState([
    { name: "fiftyFifty", isDisabled: false },
    { name: "doubleDip", isDisabled: false },
    { name: "phoneAFriend", isDisabled: false },
  ]);
  const [quit, setQuit] = useState(false);
  const [shouldDisplayQuestions, setShouldDisplayQuestions] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [correct_answer, setCorrectAnswer] = useState(-1);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [introAudio, setIntroAudio] = useState(new Audio(introAudioString));
  const [suspenseAudio, setSuspenseAudio] = useState(
    new Audio("/assets/audios/suspense1.mp3")
  );
  const [newAudio, setNewAudio] = useState(new Audio(newAudioString));
  const [wrongAudio, setWrongAudio] = useState(new Audio(wrongAudioString));
  const [lockAudio, setLockAudio] = useState(new Audio(lockAudioString));
  const [lifelineAudio, setLifelineAudio] = useState(
    new Audio(lifelineAudioString)
  );
  const [usingDoubleDip, setUsingDoubleDip] = useState(0);
  const [usingLifeline, setUsingLifeline] = useState([]);
  const [lifeLinePrompt, setLifelinePrompt] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldDisplayPrize, setShouldDisplayPrize] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const [timer, setTimer] = useState(60);
  const [timerActive, setTimerActive] = useState(false);
  const [phoneAFriendTimer, setPhoneAFriendTimer] = useState(70);
  const [phoneAFriendActive, setPhoneAFriendActive] = useState(false);
  const [mainTimerPaused, setMainTimerPaused] = useState(false);
  const [timeUp, setTimeUp] = useState(false);
  const [currentRound, setCurrentRound] = useState(0);
  const [doubleDipActive, setDoubleDipActive] = useState(false);
  const [doubleDipFirstAttempt, setDoubleDipFirstAttempt] = useState(true);
  const [answerRevealed, setAnswerRevealed] = useState(false);
  const questionAnsweredRef = useRef(false);
  const [lastQuestionCorrect, setLastQuestionCorrect] = useState(false);

  const getPrize = () => {
    if (currentQuestion <= 0) {
      return 0;
    } else if (quit) {
     if(currentQuestion === 0){
      return 0;
     }
     else{
      return prizeMap[currentQuestion - 1];
     }

    } else if (currentQuestion + 1 <= 5) {
      return 0;
    } else if (currentQuestion + 1 <= 10) {
      return prizeMap[4];
    } else if (currentQuestion + 1 < 14) {
      return prizeMap[9];
    } else if (currentQuestion + 1 === 14) {
      if (lastQuestionCorrect) {
        return prizeMap[13];
      } else {
        return prizeMap[9];
      }
    } else {
      return 0;
    }
  };
  const isPlaying = (audio) => {
    return !audio.paused;
  };

  const renderPlayButton = () => {
    return playMode ? null : <button onClick={playStarted}>Play</button>;
  };

  const toggleTimer = () => {
    setTimerActive((prevActive) => !prevActive);
    if (isPlaying(suspenseAudio)) {
      suspenseAudio.pause();
      suspenseAudio.currentTime = 0;
    } else {
      suspenseAudio.play();
    }
  };

  const renderNextButton = () => {
    return (
      <div className="button-container">
        {showNextButton && (
          <button className="next-button" onClick={handleNextQuestion}>
            Next Question
          </button>
        )}
        {shouldDisplayQuestions && currentQuestion < 10 && !timeUp && !answerRevealed && !questionAnsweredRef.current && (
          <button className="stop-timer-button" onClick={toggleTimer}>
            {timerActive ? "Stop Timer" : "Start Timer"}
          </button>
        )}
      </div>
    );
  };

  const fiftyFifty = () => {
    setUsingLifeline([...usingLifeline, 0]);
    const newQuestions = [...questions];
    const newQuestion = { ...newQuestions[currentQuestion] };
    const correctAnswer = newQuestion.answer;
    const wrongOptions = newQuestion.options.filter(
      (option) => option !== correctAnswer
    );

    // Randomly select one wrong option
    const randomWrongOption =
      wrongOptions[Math.floor(Math.random() * wrongOptions.length)];

    // Create new options array with correct answer and one wrong option
    const newOptions = [correctAnswer, randomWrongOption].sort(
      () => Math.random() - 0.5
    );

    newQuestion.options = newOptions;
    newQuestion.correctAnswerIndex = newOptions.indexOf(correctAnswer);
    newQuestions[currentQuestion] = newQuestion;
    setQuestions(newQuestions);
  };
  const doubleDip = () => {
    setUsingLifeline([...usingLifeline, 1]);
    setDoubleDipActive(true);
    setDoubleDipFirstAttempt(true);
  };
  const phoneAFriend = () => {
    setUsingLifeline([...usingLifeline, 2]);
    setPhoneAFriendActive(true);
    setPhoneAFriendTimer(70);
    setMainTimerPaused(true);
  };

  const getLifelineByIndex = (index) => {
    switch (index) {
      case 0:
        fiftyFifty();
        break;
      case 1:
        doubleDip();
        break;
      case 2:
        phoneAFriend();
        break;
      default:
        break;
    }
  };

  const lifelineClick = (index) => {
    if (
      (currentQuestion <= 6 && (timeUp || !timerActive)) ||
      liflines[index].isDisabled
    )
      return; // Check for timeUp

    if (liflines[index].isDisabled) {
      return;
    }
    const newLifelines = [...liflines];
    newLifelines[index].isDisabled = true;
    setLifelines(newLifelines);
    lifelineAudio.play();
    getLifelineByIndex(index);
  };

  const renderPhoneAFriendModal = () => {
    if (!phoneAFriendActive) return null;

    const minutes = Math.floor(phoneAFriendTimer / 60);
    const seconds = phoneAFriendTimer % 60;

    const handleCutCall = () => {
      setPhoneAFriendActive(false);
      setMainTimerPaused(false);
    };

    return (
      <div className="phone-a-friend-modal">
        <div className="modal-content">
          <h2>Phone A Friend</h2>
          <img src="/assets/images/phoneAFriend.png" alt="Phone a Friend" />
          <div className="question-display">
            <h3>Current Question:</h3>
            <p>{questions[currentQuestion]?.question}</p>
            <div className="options">
              {questions[currentQuestion]?.options.map((option, index) => (
                <p key={index}>{option}</p>
              ))}
            </div>
          </div>
          <div className="timer-text">
            Time left: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </div>
          <button className="cut-call-button" onClick={handleCutCall}>
            Cut Call
          </button>
        </div>
      </div>
    );
  };

  const renderLifeLines = () => {
    return <Lifeline lifelines={liflines} onClick={lifelineClick} />;
  };

  const renderPrize = () => {
    return (
      <div className="prize__container">
        <div className="prize__text">Prize Won</div>
        <div className="prize__amount"> ₹ {getPrize()}</div>
        {renderNextRoundButton()}
      </div>
    );
  };

  const renderTimer = () => {
    if (currentQuestion < 10 && shouldDisplayQuestions && !isLoading) {
      return (
        <>
          <div className="timer-container">
            <div class="fruit-line">
              <div class="fruit"></div>
              <div class="fruit"></div>
              <div class="fruit"></div>
              <div class="fruit"></div>
              <div class="fruit"></div>
              <div class="fruit"></div>
              <div class="fruit"></div>
              <div class="fruit"></div>
            </div>
            <div className="question__timer-bar">{timer}
            </div>

            <div class="fruit-line">
              <div class="fruit"></div>
              <div class="fruit"></div>
              <div class="fruit"></div>
              <div class="fruit"></div>
              <div class="fruit"></div>
              <div class="fruit"></div>
              <div class="fruit"></div>
              <div class="fruit"></div>
            </div>
          </div>
        </>
      );
    }
    return null;
  };

  const renderPlayConsole = () => {
    return playMode ? (
      <div className="playConsole">
        <div className="playConsole__container">
          <img src="/assets/images/KBL.png" alt="Logo" className="logo__img" />
          {shouldDisplayQuestions && loadQuestionComponent()}
          {shouldDisplayQuestions && renderLifeLines()}
          {shouldDisplayQuestions && renderQuitButton()}
          {shouldDisplayPrize && renderPrize()}
        </div>
        <QuestionList questions={prizeMap} current={currentQuestion} />
      </div>
    ) : null;
  };

  const playStarted = () => {
    introAudio.play();
    introAudio.addEventListener("ended", () => {
      suspenseAudio.loop = true;
      setTimeout(() => {
        suspenseAudio.play();
      }, 4000);
      setShouldDisplayQuestions(true);
      setIntroAudio(new Audio(introAudioString));
      loadQuestion();
    });
    setPlayMode(true);
  };

  const loadQuestion = useCallback(() => {
    setIsLoading(true);
    setQuestions(roundData[currentRound]);
    setIsLoading(false);
  }, [currentRound]);

  const loadQuestionComponent = () => {
    if (isLoading) {
      return (
        <div className="loading__container">
          Loading Questions...
          <div className="loading__circle"></div>
        </div>
      );
    } else if (questions.length > 0 && currentQuestion < questions.length) {
      return (
        <>
          <Question
            question={questions[currentQuestion].question}
            round={currentRound + 1}
            options={questions[currentQuestion].options}
            onAnswer={onAnswer}
            correctAnswer={correct_answer}
            doubleDipActive={doubleDipActive}
            doubleDipFirstAttempt={doubleDipFirstAttempt}
            answerRevealed={answerRevealed}
            renderTimer={renderTimer}
          />
        </>
      );
    }
    return null;
  };

  const handleTimeUp = () => {
    if (currentQuestion + 1 <= 10) {
      suspenseAudio.pause();
      setCorrectAnswer(questions[currentQuestion].correctAnswerIndex);
      setTimerActive(false);
      setTimeUp(true);
      setShouldDisplayQuestions(false);
      setShouldDisplayPrize(true);
    }
  };

  const startTimer = useCallback(() => {
    if (currentQuestion + 1 <= 10) {
      setTimer(60);
      setTimeUp(false);
      setTimeout(() => {
        setTimerActive(true);
      }, 4000);
    } else {
      setTimerActive(false);
      setTimeUp(false);
    }
  }, [currentQuestion]);
  useEffect(() => {
    let interval;
    if (phoneAFriendActive && phoneAFriendTimer > 0) {
      interval = setInterval(() => {
        setPhoneAFriendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (phoneAFriendTimer === 0) {
      setPhoneAFriendActive(false);
      setMainTimerPaused(false); // Resume the main timer
    }
    return () => clearInterval(interval);
  }, [phoneAFriendActive, phoneAFriendTimer]);

  useEffect(() => {
    if (shouldDisplayQuestions && !isLoading) {
      loadQuestion();
    }
  }, [shouldDisplayQuestions, isLoading, loadQuestion]);

  useEffect(() => {
    if (currentQuestion <= 0) {
      return;
    }

    suspenseAudio.pause();
    newAudio.play();

    const handleNewAudioEnd = () => {
      // Check if the question is still unanswered when the new audio ends
      if (
        !isPlaying(suspenseAudio) &&
        correct_answer === -1 &&
        !answerRevealed &&
        !questionAnsweredRef.current &&
        currentQuestion < 10
      ) {
        suspenseAudio.play();
      }
    };

    newAudio.addEventListener("ended", handleNewAudioEnd);

    // Reset the questionAnsweredRef for the new question
    questionAnsweredRef.current = false;

    // Cleanup function
    return () => {
      newAudio.removeEventListener("ended", handleNewAudioEnd);
      newAudio.pause();
      newAudio.currentTime = 0;
      suspenseAudio.pause();
      suspenseAudio.currentTime = 0;
      setNewAudio(new Audio(newAudioString));
    };
  }, [currentQuestion]);

  useEffect(() => {
    if (shouldDisplayPrize) {
      suspenseAudio.pause();
      introAudio.loop = true;
      introAudio.play();
    }
  }, [shouldDisplayPrize]);

  useEffect(() => {
    let interval;
    if (currentQuestion < 10 && timerActive && timer > 0 && !mainTimerPaused) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (currentQuestion <= 6 && timer === 0 && !timeUp) {
      clearInterval(interval);
      handleTimeUp();
    }
    return () => clearInterval(interval);
  }, [currentQuestion, timerActive, timer, mainTimerPaused, timeUp]);

  useEffect(() => {
    if (shouldDisplayQuestions && !isLoading) {
        startTimer();
    }
  }, [shouldDisplayQuestions, isLoading, startTimer]);

  const handleAnswerResult = (isCorrect) => {
    if (currentQuestion === questions.length - 1) {
      // Last question
      setLastQuestionCorrect(isCorrect);
      setTimeout(() => {
        setShouldDisplayQuestions(false);
        setShouldDisplayPrize(true);
      }, 1000);
    } else if (isCorrect) {
      suspenseAudio.pause();
      setShowNextButton(true);
    } else {
      setTimeout(() => {
        setShouldDisplayQuestions(false);
        setShouldDisplayPrize(true);
      }, 1000);
    }
  };

  const onAnswer = (index) => {
    if (currentQuestion < 10 && timeUp) return;
    if (!doubleDipActive && correct_answer !== -1) return; // Prevent clicks after normal answer
    setAnswerRevealed(false);
    setTimerActive(false);
    suspenseAudio.pause();
    suspenseAudio.currentTime = 0;
    questionAnsweredRef.current = true;

    const correctAnswerIndex = questions[currentQuestion].options.indexOf(
      questions[currentQuestion].answer
    );

    lockAudio.play();
    lockAudio.addEventListener(
      "ended",
      () => {
        setAnswerRevealed(true);
        if (doubleDipActive && doubleDipFirstAttempt) {
          // First attempt of Double Dip
          if (index === correctAnswerIndex) {
            // Correct answer on first attempt
            setCorrectAnswer(index);
            handleAnswerResult(true);
          } else {
            // Wrong answer on first attempt
            setCorrectAnswer(-1); // Don't show any color
            wrongAudio.play();
            wrongAudio.addEventListener(
              "ended",
              () => {
                setDoubleDipFirstAttempt(false);
                if((currentQuestion + 1) <= 10) {
                  setTimerActive(true);
                  suspenseAudio.play();
                }
              },
              { once: true }
            );
          }
        } else {
          // Normal answer or second attempt of Double Dip
          if (index === correctAnswerIndex) {
            setCorrectAnswer(index);
            handleAnswerResult(true);
          } else {
            setCorrectAnswer(correctAnswerIndex); // Show the correct answer immediately
            wrongAudio.play();
            wrongAudio.addEventListener(
              "ended",
              () => {
                handleAnswerResult(false);
              },
              { once: true }
            );
          }
          setDoubleDipActive(false);
          setDoubleDipFirstAttempt(true);
        }
      },
      { once: true }
    );

    setLockAudio(new Audio(lockAudioString));
    setWrongAudio(new Audio(wrongAudioString));
  };
  const handleNextQuestion = () => {
    const nextQuestionIndex = currentQuestion + 1;
    if (nextQuestionIndex < questions.length) {
      setCurrentQuestion(nextQuestionIndex);
      setAnswerRevealed(false);
      questionAnsweredRef.current = false;
      setCorrectAnswer(-1);
      setShowNextButton(false);
      setDoubleDipActive(false);
      setDoubleDipFirstAttempt(true);
      if (nextQuestionIndex <= 10) {
        if (!isPlaying(suspenseAudio)) {
          suspenseAudio.src = `/assets/audios/suspense1.mp3`;
          suspenseAudio.play();
        }
        startTimer();
      }
    } else {
      // Round completed
      setShouldDisplayQuestions(false);
      setShouldDisplayPrize(true);
    }
  };

  const handleNextRound = () => {
    // Stop the intro audio
    introAudio.pause();
    introAudio.currentTime = 0;
    setTimerActive(false)
    setTimeUp(false)
    questionAnsweredRef.current = false;
    setAnswerRevealed(false);
    setCurrentRound((prevRound) => prevRound + 1);
    setShowNextButton(false)
    setCurrentQuestion(0);
    setCorrectAnswer(-1);
    setUsingDoubleDip(0);
    setUsingLifeline([]);
    setLifelines([
      { name: "fiftyFifty", isDisabled: false },
      { name: "doubleDip", isDisabled: false },
      { name: "phoneAFriend", isDisabled: false },
    ]);
    setShouldDisplayPrize(false);
    setShouldDisplayQuestions(true);

    // Play the new audio
    newAudio.play();
    newAudio.addEventListener(
      "ended",
      () => {
        suspenseAudio.play();
        setNewAudio(new Audio(newAudioString));
      },
      { once: true }
    );

    loadQuestion();
  };
  const renderNextRoundButton = () => {
    const isLastRound = currentRound === 4;

    return !isLastRound ? (
      <button className="next-round-button" onClick={handleNextRound}>
        Next Round
      </button>
    ) : null;
  };

  const renderLifelinePrompt = () => {
    let usedLifelines = liflines.filter(
      (lifeline, index) =>
        lifeline.isDisabled &&
        lifeline.name !== "phoneAFriend" &&
        !usingLifeline.includes(index)
    );
    usedLifelines = usedLifelines.map((lifeline) => {
      return {
        ...lifeline,
        isDisabled: false,
      };
    });
    const myLifelineClick = (index) => {
      const lifelineIndex = lifelineIndexMap[usedLifelines[index].name];
      getLifelineByIndex(lifelineIndex);
    };
    return lifeLinePrompt && usedLifelines && usedLifelines.length > 0 ? (
      <div className="glass">
        <div className="lifeline__prompt">
          <Lifeline
            lifelines={usedLifelines}
            onClick={(index) => {
              myLifelineClick(index);
              setLifelinePrompt(false);
            }}
          />
        </div>
      </div>
    ) : null;
  };

  const renderQuitButton = () => {
    return (
      <div className="quit__button">
        <button
          onClick={() => {
            setShouldDisplayQuestions(false);
            setUsingLifeline([]);
            setQuit(true);
            setShouldDisplayPrize(true);
          }}
        >
          Quit
        </button>
      </div>
    );
  };

  return (
    <div className="App">
      {renderPlayButton()}
      {renderNextButton()}
      {renderPlayConsole()}
      {renderLifelinePrompt()}
      {renderPhoneAFriendModal()}
    </div>
  );
}

export default App;
